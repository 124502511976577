<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <div class="col-12">
                    <label class="col-12">Título</label>
                    <div class="p-inputgroup pt-2 col-12">
                        <InputText v-model="busca" @keyup.enter="buscar" />
                        <Button label="buscar" icon="pi pi-search" iconPos="right" @click.stop="buscar" />
                    </div>
                    <div class="field col-12 md:col-19">
                        <div class="grid row align-items-end">
                            <div class="field col-12 md:col-4">
                                <label>Público Alvo</label>
                                <MultiSelect v-model="publico" :options="publicosAlvos" optionLabel="title" optionValue="id" showClear />
                            </div>
                            <div class="field col-12 md:col-4">
                                <label>Setor Econômico</label>
                                <MultiSelect v-model="setor" :options="setoresEconomicos" optionLabel="title" optionValue="id" showClear />
                            </div>
                            <div class="field col-12 md:col-4">
                                <label>Persona</label>
                                <MultiSelect v-model="persona" :options="personas" optionLabel="title" optionValue="id" showClear />
                            </div>
                            <div class="field col-12 md:col-4">
                                <label>Origem de Contratação</label>
                                <MultiSelect v-model="origem" :options="origensContratacoes" optionLabel="title" optionValue="id" showClear />
                            </div>
                            <div class="field col-12 md:col-4">
                                <label>Complexidade</label>
                                <MultiSelect v-model="complexiade" :options="complexiades" optionLabel="title" optionValue="id" showClear />
                            </div>
                            <div class="field col-12 md:col-4">
                                <label>Instrumento</label>
                                <MultiSelect v-model="instrumento" :options="instrumentos" optionLabel="title" optionValue="id" showClear />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Services from './service';
import TemaService from '../tema/service';
import PublicoAlvoService from '../publicoalvo/service';
import SetorService from '../setoreconomico/service';
import PersonaService from '../personas/service';
import OrigemService from '../origemcontratacao/service';
import ComplexidadeService from '../complexidade/service';
import InstrumentoService from '../instrumentos/service';

export default {
    data() {
        return {
            data: [],
            temas: [],
            personas: [],
            publicosAlvos: [],
            setoresEconomicos: [],
            origensContratacoes: [],
            complexiades: [],
            instrumentos: [],
            tema: [],
            setor: [],
            origem: [],
            publico: [],
            persona: [],
            complexiade: [],
            instrumento: [],
            title: '',
            busca: '',
            url: '',
            temaUrl: '',
            publicoUrl: '',
            setorUrl: '',
            personaUrl: '',
            origemUrl: '',
            complexiadeUrl: '',
            instrumentoUrl: '',
        };
    },

    emits: ['listagem'],

    mounted() {
        this.buscarTemas();
        this.buscarPublicosAlvos();
        this.buscarSetoresEconomicos();
        this.buscarPersonas();
        this.buscarOrigensContratacoes();
        this.buscarComplexiades();
        this.buscarInstrumentos();
    },

    methods: {
        buscar() {
            this.montarUrl();
            this.$store.dispatch('addRequest');
            Services.buscarAvancada(this.url).then((response) => {
                if (response?.success) {
                    this.data = response.data;
                }
                this.$emit('listagem', this.data);
                this.$store.dispatch('removeRequest');
            });
        },
        buscarTemas() {
            this.$store.dispatch('addRequest');
            TemaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.temas = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        buscarPublicosAlvos() {
            this.$store.dispatch('addRequest');
            PublicoAlvoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.publicosAlvos = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        buscarSetoresEconomicos() {
            this.$store.dispatch('addRequest');
            SetorService.obterTodos().then((response) => {
                if (response?.success) {
                    this.setoresEconomicos = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        buscarPersonas() {
            this.$store.dispatch('addRequest');
            PersonaService.obterTodos().then((response) => {
                if (response?.success) {
                    this.personas = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        buscarOrigensContratacoes() {
            this.$store.dispatch('addRequest');
            OrigemService.obterTodos().then((response) => {
                if (response?.success) {
                    this.origensContratacoes = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        buscarComplexiades() {
            this.$store.dispatch('addRequest');
            ComplexidadeService.obterTodos().then((response) => {
                if (response?.success) {
                    this.complexiades = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        buscarInstrumentos() {
            this.$store.dispatch('addRequest');
            InstrumentoService.obterTodos().then((response) => {
                if (response?.success) {
                    this.instrumentos = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        montarUrl() {
            this.url =
                this.title + this.temaUrl + this.publicoUrl + this.setorUrl + this.personaUrl + this.origemUrl + this.complexiadeUrl + this.instrumentoUrl;
        },
    },

    watch: {
        tema() {
            this.temaUrl = '';
            this.tema.forEach((temaId) => {
                if (this.tema) {
                    this.temaUrl = this.temaUrl + '&tema=' + temaId;
                }
            });
        },
        publico() {
            this.publicoUrl = '';
            this.publico.forEach((publicoId) => {
                if (this.publico) {
                    this.publicoUrl = this.publicoUrl + '&publicoAlvo=' + publicoId;
                }
            });
        },
        setor() {
            this.setorUrl = '';
            this.setor.forEach((setorId) => {
                if (this.setor) {
                    this.setorUrl = this.setorUrl + '&setor=' + setorId;
                }
            });
        },
        persona() {
            this.personaUrl = '';
            this.persona.forEach((personaId) => {
                if (this.persona) {
                    this.personaUrl = this.personaUrl + '&personas=' + personaId;
                }
            });
        },
        origem() {
            this.origemUrl = '';
            this.origem.forEach((origemId) => {
                if (this.origem) {
                    this.origemUrl = this.origemUrl + '&origemContratacao=' + origemId;
                }
            });
        },
        complexiade() {
            this.complexiadeUrl = '';
            this.complexiade.forEach((complexidadeId) => {
                if (this.complexiade) {
                    this.complexiadeUrl = this.complexiadeUrl + '&complexidade=' + complexidadeId;
                }
            });
        },
        instrumento() {
            this.instrumentoUrl = '';
            this.instrumento.forEach((instrumentoId) => {
                if (this.instrumento) {
                    this.instrumentoUrl = this.instrumentoUrl + '&instrumento=' + instrumentoId;
                }
            });
        },
        busca() {
            this.title = '&title=' + this.busca;
        },
    },
};
</script>
