import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

const urlBase = `${api}`;

export default {
    obterTodos() {
        return axiosJwt.get(`${urlBase}/solucoes/vitrine`);
    },
    buscarAvancada(busca) {
        return axiosJwt.get(`${urlBase}/solucoes/buscaavancada?${busca}`);
    },
};