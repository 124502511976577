<template>
    <painel titulo="Vitrine" icone="pi pi-sliders-h" :refreshFunction="obterPortifolios">
        <busca-avancada @listagem="listarBuscas" v-if="mostrarVitrine"></busca-avancada>
        <!-- <carrosel-portifolio :data="data" v-if="mostrarVitrine"></carrosel-portifolio> -->
    </painel>
    <router-view></router-view>
</template>

<script>
// import CarroselPortifolio from './CarroselPortifolio.vue';
import BuscaAvancada from './BuscaAvancada.vue';
import Services from './service';

export default {
    components: {
        BuscaAvancada,
        // CarroselPortifolio,
    },

    data() {
        return {
            data: [],
            buscas: [],
        };
    },

    mounted() {
        // this.obterPortifolios();
    },
    computed: {
        mostrarVitrine() {
            return this.$route.name === 'Vitrine';
        },
    },

    methods: {
        obterPortifolios() {
            this.$store.dispatch('addRequest');
            Services.obterTodos().then((response) => {
                if (response?.success) {
                    this.data = response.data;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        listarBuscas(objeto) {
            this.$router.push({
                name: `Vitrine_Busca_Avancada`,
                params: {
                    objeto: JSON.stringify(objeto),
                },
            });
        },
        voltar() {
            this.mostrarVitrine = true;
            this.obterPortifolios();
        },
    },
    watch: {
        mostrarVitrine() {
            if (this.mostrarVitrine) {
                this.obterPortifolios();
            }
        },
    },
};
</script>

<style>
</style>
